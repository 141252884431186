import React from 'react'
import {withRouter } from 'react-router-dom'
import Dialog from '../../components/dialog/dialog.component'
import Loading from '../../components/loading/loading.component'
import { connect } from 'react-redux'
import Filters from './filters.component'
import Pagination from '../../components/pagination/pagination.component'
import './orders.styles.scss'
import Service from './service'
import NumberFormat from 'react-number-format'

class Orders extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadOrders()
    }

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page
        }, () => this.service.loadOrders())
    }

    onFilterChange = e => {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                value: "",
                [e.target.name]: e.target.value
            }
        })
    }

    onFilterSubmit = e => {
        e.preventDefault()
        this.service.loadOrders()
    }

    getStatusLabel = status => {
        switch (status) {
            case 1:
                return <span className="pending">Awaiting Payment</span>
            case 2:
                return <span className="pending">Processing</span>
            case 3:
                return <span className="active">Awaiting Delivery</span>
            case 4:
                return <span className="active">Delivered</span>
            case 5:
                return <span className="inactive">Cancelled</span>
            default:
                return <span className="pending">Unknown</span>
        }
    }

    render() {
        return (
            <>
                {this.state.loading && <Dialog><Loading /></Dialog>}
                <div className="orders">
                    <div className="header">
                        <h4>Orders</h4>
                        <div className="buttons">
                            <Filters filter={this.state.filter} onSubmit={this.onFilterSubmit} onChange={this.onFilterChange} />
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Number</th>
                                <th>Status</th>
                                <th>Amount(N)</th>
                                <th>Paid(N)</th>
                                <th>Balance(N)</th>
                                <th>Date</th>
                                <th>Updated</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.orders.map((order, key) => <tr key={key} onClick={() => this.props.history.push('/orders/' + order.number)}>
                                    <td>{order.number}</td>
                                    <td>{this.getStatusLabel(order.status)}</td>
                                    <td><NumberFormat value={order.amount} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} /></td>
                                    <td><NumberFormat value={order.invoices[0].paid} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} /></td>
                                    <td><NumberFormat value={order.invoices[0].balance} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} /></td>
                                    <td>{new Date(order.createdAt).toString('dd MMM, yyyy')}</td>
                                    <td>{new Date(order.updatedAt).toString('dd MMM, yyyy')}</td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Orders))