import React from 'react'
import { Switch, Route } from "react-router-dom"
import Products from './products.page'
import Create from './create/create.page'
import Edit from './edit/edit.page'

const ProductController = () => {
    return (
        <Switch>
            <Route path="/products/create" component={Create} />
            <Route path="/products/:id" component={Edit} />
            <Route path="/products" component={Products} />
        </Switch>
    )
}

export default ProductController