import React from 'react'
import './change-status.styles.scss'
import Worker from './change-status.worker'
import Loading from '../../../../components/loading/loading.component'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

class ChangeStatus extends React.Component {

    constructor(props) {
        super(props)
        this.worker = Worker(this)
        this.state = this.worker.getDefaultState()
    }

    render() {
        return (
            <div className="change-status card">
                <div className="header">
                    <h4>Change Status</h4>
                    <span onClick={this.props.onCancel}>X</span>
                </div>
                {this.state.uploading ? <Loading /> : <div className="menu">
                    <button onClick={() => this.worker.saveStatus(1)}>Awaiting Payment</button>
                    <button onClick={() => this.worker.saveStatus(2)}>Processing</button>
                    <button onClick={() => this.worker.saveStatus(3)}>Awaiting Delivery</button>
                    <button onClick={() => this.worker.saveStatus(4)}>Delivered</button>
                    <button onClick={() => this.worker.saveStatus(5)}>Cancelled</button>
                </div>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(ChangeStatus))