import { API_ENDPOINT } from '../../../../config'
import axios from 'axios'
import { StringValidationRule, NumberValidationRule, DateValidationRule } from "../../../../data/validation/rules"
import Validation from "../../../../data/validation/validation"

const getDefaultState = () => {
    return {
        saving: false,
        amount: "",
        method: "",
        reference: "",
        date: "",
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(NumberValidationRule, instance.state.amount, (error) => errors.amount = error, { min: { value: .1, error: "Too low" }, max: { value: 100000000, error: "Too high" } })
    validation.addValidationRule(StringValidationRule, instance.state.method, (error) => errors.method = error, { min: { value: 1, error: "Invalid" }, max: { value: 1, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.reference, (error) => errors.reference = error, { min: { value: 0, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    validation.addValidationRule(DateValidationRule, instance.state.date, (error) => errors.date = error, { allowNull: false})
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const savePayment = instance => {
    instance.setState({
        ...instance.state,
        saving: true,
        flag: {
            type: "",
            text: ""
        }
    })

    axios({
        method: 'post',
        url: API_ENDPOINT + "/billing/payments/create",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            amount: instance.state.amount,
            method: instance.state.method,
            reference: instance.state.reference,
            date: new Date(instance.state.date).getTime()/1000,
            id: instance.props.id
        }
    }).then(response => handleSavePaymentResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSavePaymentResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                saving: false
            })
            instance.props.onPaymentReceived(response.data.data.payment)
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const Worker = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        savePayment: () => savePayment(instance)
    }
}

export default Worker