import React from 'react'
import Service from './details.service'
import { Link, withRouter } from 'react-router-dom'
import './details.styles.scss'
import { connect } from 'react-redux'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import iconPayer from '../../../assets/icons/payer.svg'
import Files from './components/files/files.component'
import { FindOptionByValue } from '../../../utils/UIHelper'
import DistributorCategories from '../../../assets/data/distributor_categories'

class Details extends React.Component {
    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.load()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.service.isValid() && this.service.save()
    }

    render() {
        return (
            <div id="details" className="details">
                {(this.state.loading || this.state.approving) && <Dialog><Loading /></Dialog>}
                <div className="header">
                    <h4>Distributor's Info</h4>
                    <div className="buttons">
                        <Link className="btn" to="/distributors/approved"><img alt="" src={iconPayer} />Return</Link>
                    </div>
                </div>
                <div className="card">
                    <div className="header">
                        <h4>Company Info</h4>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Name</label>
                            <p>{this.state.name}</p>
                        </div>
                        <div className="col">
                            <label>RC Number</label>
                            <p>{this.state.rcNumber}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Office</label>
                            <p>{this.state.office}</p>
                        </div>
                        <div className="col">
                            <label>Warehouse</label>
                            <p>{this.state.warehouse}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>TIN</label>
                            <p>{this.state.tin}</p>
                        </div>
                        <div className="col">
                            <label>Managing Director's BVN</label>
                            <p>{this.state.bvn}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Category</label>
                            <p>{FindOptionByValue(DistributorCategories, Number(this.state.category)).label}</p>
                        </div>
                        <div className="col">
                            <label>Bank Guarantee</label>
                            <p>{this.state.bankGuaranteeProvided ? 'Yes' : 'No'}</p>
                        </div>
                    </div>
                    <div className="header">
                        <h4>Contact Person</h4>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Name</label>
                            <p>{this.state.accesses[0].firstname} {this.state.accesses[0].lastname}</p>
                        </div>
                        <div className="col">
                            <label>Phone</label>
                            <p>{this.state.accesses[0].phone}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>Email</label>
                            <p>{this.state.accesses[0].username}</p>
                        </div>
                    </div>
                    <Files key={Math.random()} data={this.state.files} />
                    {
                        this.state.status !== 3 && <button className="btn a" onClick={() => this.service.approve()}>Approve</button>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Details))