import React from 'react'
import './form.styles.scss'

const RoleForm = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="row">
                    <div className="form-label">
                        <label>Name</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="name" value={props.name} onChange={props.onChange} />
                        <span className="error">{props.errors.name}</span>
                    </div>
                </div>
                {props.children}
            </div>
            <div className="actions">
                <button type="submit" onClick={props.onSubmit}>{props.action}</button>
                <button type="reset" onClick={props.onCancel}>Cancel</button>
            </div>
        </form>
    )
}

export default RoleForm