import React from 'react'
import { withRouter } from 'react-router-dom'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import { connect } from 'react-redux'
import './details.styles.scss'
import Service from './details.service'
import NumberFormat from 'react-number-format'
import AddPayment from './add-payment/add-payment.component'
import ChangeStatus from './change-status/change-status.component'

class Details extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadOrder()
    }

    getStatusLabel = status => {
        switch (status) {
            case 1:
                return <span className="pending">Awaiting Payment</span>
            case 2:
                return <span className="pending">Processing</span>
            case 3:
                return <span className="active">Awaiting Delivery</span>
            case 4:
                return <span className="active">Delivered</span>
            case 5:
                return <span className="inactive">Cancelled</span>
            default:
                return <span className="pending">Unknown</span>
        }
    }

    onPaymentReceived = payment => {
        let order = this.state.order
        order.invoices[0].payments.push(payment)
        this.setState({
            ...this.state,
            order: order
        })
        this.togglePaymentDialog()
    }

    onStatusUpdated = status => {
        let order = this.state.order
        order.status = status
        this.setState({
            ...this.state,
            order: order
        })
        this.toggleStatusDialog()
    }

    togglePaymentDialog = () => this.setState({
        ...this.state,
        showPaymentDialog: !this.state.showPaymentDialog
    })

    toggleStatusDialog = () => this.setState({
        ...this.state,
        showStatusDialog: !this.state.showStatusDialog
    })

    render() {
        return (
            <>
                {this.state.loading && <Dialog><Loading /></Dialog>}
                <div className="details">
                    {this.state.showPaymentDialog && <Dialog><AddPayment id={this.state.order.invoices[0].id} onPaymentReceived={this.onPaymentReceived} onCancel={this.togglePaymentDialog} /></Dialog>}
                    {this.state.showStatusDialog && <Dialog><ChangeStatus id={this.state.order.id} onStatusUpdated={this.onStatusUpdated} onCancel={this.toggleStatusDialog} /></Dialog>}
                    <div className="header">
                        <h4>Order Details</h4>
                    </div>
                    <div className="card">
                        <div className="row">
                            <div className="col">
                                <label>Number</label>
                                <p>{this.state.order.number}</p>
                            </div>
                            <div className="col">
                                <label>Status</label>
                                <p>{this.getStatusLabel(this.state.order.status)}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Total</label>
                                <p><NumberFormat value={this.state.order.amount} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} /></p>
                            </div>
                            <div className="col">
                                <label>Paid</label>
                                <p>{this.state.order.invoices[0].paid > 0 ? <NumberFormat value={this.state.order.invoices[0].paid} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} /> : "-"}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Balance</label>
                                <p><NumberFormat value={this.state.order.invoices[0].balance} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} /></p>
                            </div>
                            <div className="col">
                                <label>Date</label>
                                <p>{new Date(this.state.order.createdAt).toString('dd MMM, yyyy')}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Special Instructions</label>
                                <p>{this.state.order.notes}</p>
                            </div>
                            <div className="col">
                                <label>Placed By</label>
                                <p>{this.state.order.placedBy.firstname} {this.state.order.placedBy.lastname} {this.state.order.placedBy.phone}</p>
                            </div>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>S/N</th>
                                <th>Item Description</th>
                                <th>Quantity</th>
                                <th>Rate(NGN)</th>
                                <th>Amount(NGN)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.order.orderLines.map((orderLine, key) => <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>{orderLine.description}</td>
                                    <td>{orderLine.quantity}</td>
                                    <td><NumberFormat value={orderLine.rate} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} /></td>
                                    <td><NumberFormat value={orderLine.amount} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} /></td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    <div className="header">
                        <h4>Payments</h4>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>S/N</th>
                                <th>Amount(NGN)</th>
                                <th>Date</th>
                                <th>Processed By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.order.invoices[0].payments.map((payment, key) => <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td><NumberFormat value={payment.amount} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} /></td>
                                    <td>{new Date(payment.date).toString('dd MMM, yyyy')}</td>
                                    <td>{payment.processedBy.username} ({payment.processedBy.firstname} {payment.processedBy.lastname})</td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    <br />
                    <div className="card">
                        <div className="buttons">
                            <button onClick={this.togglePaymentDialog}>Add Payment</button>
                            <button onClick={this.toggleStatusDialog}>Change Status</button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Details))