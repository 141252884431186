import axios from 'axios'
import { API_ENDPOINT } from '../../../config'

const getDefaultState = () => {
    return {
        loading: false,
        approving: false,
        name: "",
        rcNumber: "",
        office: "",
        warehouse: "",
        tin: "",
        bvn: "",
        category: "",
        status: -1,
        bankGuaranteeProvided: false,
        coverageZones: [],
        bankers: [],
        cylinderTypes: [],
        accesses: [
            {
                firstname: "",
                lastname: "",
                phone: "",
                username: ""
            }
        ],
        files: [],
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const load = (instance) => {
    instance.setState({
        ...instance.state,
        loading: true
    })
    axios({
        method: 'get',
        url: API_ENDPOINT + "/distributors/" + instance.props.match.params.id,
        headers: {
            "Authorization": instance.props.auth.authorization
        }
    }).then(response => handleLoadResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loading: false
            })
            alert(error)
        })
}

const handleLoadResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loading: false,
                name: response.data.data.distributor.name,
                rcNumber: response.data.data.distributor.rcNumber,
                office: response.data.data.distributor.office,
                warehouse: response.data.data.distributor.warehouse,
                tin: response.data.data.distributor.tin,
                bvn: response.data.data.distributor.bvn,
                category: response.data.data.distributor.category,
                bankGuaranteeProvided: response.data.data.distributor.isBankGuaranteeProvided,
                bankers: response.data.data.distributor.bankers,
                coverageZones: response.data.data.distributor.coverageZones,
                cylinderTypes: response.data.data.distributor.cylinderTypes,
                files: response.data.data.distributor.files,
                status: response.data.data.distributor.status,
                accesses: response.data.data.distributor.accesses
            })
            break
        default:
            instance.setState({
                ...instance.state,
                loading: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const approve = (instance) => {
    instance.setState({
        ...instance.state,
        approving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/distributors/approve",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            id: instance.props.match.params.id
        }
    }).then(response => handleApproveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                approving: false
            })
            alert(error)
        })
}

const handleApproveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                approving: false,
                status: 3
            })
            break
        default:
            instance.setState({
                ...instance.state,
                approving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        approve: () => approve(instance),
        load: () => load(instance)
    }
}

export default Service