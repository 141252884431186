import React from 'react'
import EditRoleWorker from './edit'
import { Link, withRouter } from 'react-router-dom'
import RoleForm from '../components/form/form.component'
import Checkbox from '../../../../components/inputs/checkbox/checkbox.component'
import FormHelper from '../../../../utils/FormHelper'

class EditRole extends React.Component {
    
    constructor(props) {
        super(props)
        this.editRoleWorker = EditRoleWorker(this)
        this.state = this.editRoleWorker.getDefaultState()
    }

    componentDidMount = () => {
        this.editRoleWorker.loadPermissions()
        this.editRoleWorker.loadRole()
    }

    onChangePermission = (name, checked) => this.editRoleWorker.onChangePermission(name)

    onSubmit = (e) => {
        e.preventDefault()
        this.editRoleWorker.isValid() && this.editRoleWorker.updateRole()
    }

    onCancel = () => this.props.history.push('/security/roles')

    render(){
        return(
            <div className="edit-role">
                <div className="card">
                    <Link className="btn" to="/security/roles">Roles</Link>
                    <div className="header">
                        <h4>Edit Role</h4>
                    </div>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <RoleForm {...this.state} action="Save Role" onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.onCancel}>
                        <h4>Permissions</h4>
                        <div className="row-permissions">
                            {
                                this.state.permissions.map((permission, key) => <Checkbox key={key} name={permission.id} text={permission.name} checked={this.state.grantedPermissionIds.indexOf(permission.id) > -1} onChange={this.onChangePermission} />)
                            }
                        </div>
                    </RoleForm>
                </div>
            </div>
        )
    }
}

export default withRouter(EditRole)