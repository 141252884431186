import React from 'react'
import { Switch, Route } from "react-router-dom"
import Orders from './orders.page'
import Details from './details/details.page'

const OrderController = () => {
    return (
        <Switch>
            <Route path="/orders/:number" component={Details} />
            <Route path="/orders" component={Orders} />
        </Switch>
    )
}

export default OrderController