import React from 'react'
import Dialog from '../../../../../components/dialog/dialog.component'
import Loading from '../../../../../components/loading/loading.component'
import Service from './files.service'
import './files.styles.scss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { API_ENDPOINT } from '../../../../../config'

class Files extends React.Component {

    constructor(props) {
        super(props)
        
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    render() {
        return (
            <div className="files">
                {this.state.loading && <Dialog><Loading /></Dialog>}
                <div className="header">
                    <h4>Files</h4>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>File</th>
                            <th>Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.files.map((file, key) => <tr key={key}>
                                <td>{file.name}</td>
                                <td><a href={API_ENDPOINT + '/' + file.file + '?id=' + this.props.match.params.id} target="_blank" rel="noreferrer">Download File</a></td>
                                <td>{new Date(file.createdAt).toString('dd/MM/yyyy')}</td>
                            </tr>)
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Files))