import React from 'react'
import Service from './role.service'
import { Link, withRouter } from 'react-router-dom'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import { connect } from 'react-redux'
import Filters from './filters.component'
import Pagination from '../../../components/pagination/pagination.component'
import './roles.styles.scss'
import iconNew from '../../../assets/icons/new-light.svg'

class Roles extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadRoles()
    }

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page
        }, () => this.service.loadRoles())
    }

    onFilterChange = e => {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                value: "",
                [e.target.name]: e.target.value
            }
        })
    }

    onFilterSubmit = e => {
        e.preventDefault()
        this.service.loadRoles()
    }

    onDelete = id => this.setState({
        ...this.state,
        roles: this.state.roles.filter(role => role.id !== id)
    })

    render() {
        return (
            <>
                {this.state.loading && <Dialog><Loading /></Dialog>}
                <div className="roles">
                    <div className="header">
                        <h4>Roles</h4>
                        <div className="buttons">
                        <Link className="btn" to="/security/roles/create"><img alt="" src={iconNew} />New Role</Link>
                            <Filters filter={this.state.filter} onSubmit={this.onFilterSubmit} onChange={this.onFilterChange} />
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Permissions</th>
                                <th>Created</th>
                                <th>Updated</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.roles.map((role, key) => <tr key={key}>
                                    <td>{role.name}</td>
                                    <td>{role.rolePermissions.length}</td>
                                    <td>{new Date(role.createdAt).toString('dd MMM, yyyy')}</td>
                                    <td>{new Date(role.updatedAt).toString('dd MMM, yyyy')}</td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Roles))