import React from 'react'
import './add-payment.styles.scss'
import Worker from './add-payment.worker'
import FormHelper from '../../../../utils/FormHelper'
import Form from './form.component'
import Loading from '../../../../components/loading/loading.component'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

class AddPayment extends React.Component {

    constructor(props) {
        super(props)
        this.worker = Worker(this)
        this.state = this.worker.getDefaultState()
    }

    onSubmit = e => {
        e.preventDefault()
        this.worker.isValid() && this.worker.savePayment()
    }

    render() {
        return (
            <div className="add-payment card">
                <div className="header">
                    <h4>Add Payment</h4>
                </div>
                <div className={"flag " + this.state.flag.type}>
                    {this.state.flag.text}
                </div>
                {this.state.uploading? <Loading/>:<Form {...this.state} onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.props.onCancel}/>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(AddPayment))