import React from 'react'

const Form = props => {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="inputs">
                <div className="row" id="amount">
                    <div className="form-label">
                        <label>Amount</label>
                    </div>
                    <div className="form-input">
                        <input type="number" name="amount" value={props.amount} onChange={props.onChange} />
                        <span className="error">{props.errors.amount}</span>
                    </div>
                </div>
                <div className="row" id="method">
                    <div className="form-label">
                        <label>Method</label>
                    </div>
                    <div className="form-input">
                        <select name='method' defaultValue={props.method} onChange={props.onChange}>
                            <option value=""></option>
                            <option value="1">Bank Transfer</option>
                            <option value="2">Cheque</option>
                            <option value="3">Cash</option>
                        </select>
                        <span className="error">{props.errors.method}</span>
                    </div>
                </div>
                <div className="row" id="reference">
                    <div className="form-label">
                        <label>Reference</label>
                    </div>
                    <div className="form-input">
                        <input type="number" name="reference" value={props.reference} onChange={props.onChange} />
                        <span className="error">{props.errors.reference}</span>
                    </div>
                </div>
                <div className="row" id="date">
                    <div className="form-label">
                        <label>Date of Payment</label>
                    </div>
                    <div className="form-input">
                        <input type="date" name="date" value={props.date} onChange={props.onChange} />
                        <span className="error">{props.errors.date}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="submit">Add</button>
                <button type="reset" onClick={props.onCancel}>Cancel</button>
            </div>
        </form>
    )
}

export default Form