import React from 'react'

const UserForm = (props) => {
    const renderMore = (props) => {
        return (
            <>
                <div className="row">
                    <div className="form-label">
                        <label>Username</label>
                    </div>
                    <div className="form-input">
                        <input type="email" name="username" value={props.username} onChange={props.onChange} />
                        <span className="error">{props.errors.username}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Password</label>
                    </div>
                    <div className="form-input">
                        <input type="password" name="password" value={props.password} onChange={props.onChange} />
                        <span className="error">{props.errors.password}</span>
                    </div>
                </div>
            </>
        )
    }
    return (
        <form>
            <div className="inputs">
                <div className="row">
                    <div className="form-label">
                        <label>Firstname</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="firstname" value={props.firstname} onChange={props.onChange} />
                        <span className="error">{props.errors.firstname}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Lastname</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="lastname" value={props.lastname} onChange={props.onChange} />
                        <span className="error">{props.errors.lastname}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Phone</label>
                    </div>
                    <div className="form-input">
                        <input type="phone" name="phone" value={props.phone} onChange={props.onChange} />
                        <span className="error">{props.errors.phone}</span>
                    </div>
                </div>
                {props.action === "Add User" && renderMore(props)}
                {props.children}
            </div>
            <div className="actions">
                <button type="submit" onClick={props.onSubmit}>{props.action}</button>
                <button type="reset" onClick={props.onCancel}>Cancel</button>
            </div>
        </form>
    )
}

export default UserForm