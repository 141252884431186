import React from 'react'

const Form = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="row">
                    <div className="form-label">
                        <label>Name</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="name" value={props.name} onChange={props.onChange} />
                        <span className="error">{props.errors.name}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Description</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="description" value={props.description} onChange={props.onChange} />
                        <span className="error">{props.errors.description}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Distributor Price</label>
                    </div>
                    <div className="form-input">
                        <input type="number" name="distributorPrice" value={props.distributorPrice} onChange={props.onChange} />
                        <span className="error">{props.errors.distributorPrice}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Mega Distributor Price</label>
                    </div>
                    <div className="form-input">
                        <input type="number" name="megaDistributorPrice" value={props.megaDistributorPrice} onChange={props.onChange} />
                        <span className="error">{props.errors.megaDistributorPrice}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Super Distributor Price</label>
                    </div>
                    <div className="form-input">
                        <input type="number" name="superDistributorPrice" value={props.superDistributorPrice} onChange={props.onChange} />
                        <span className="error">{props.errors.superDistributorPrice}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="submit" onClick={props.onSubmit}>Save</button>
                <button type="reset" onClick={props.onCancel}>Cancel</button>
            </div>
        </form>
    )
}

export default Form