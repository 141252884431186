import axios from 'axios'
import { API_ENDPOINT } from '../../../config'
import Validation from '../../../data/validation/validation'
import { StringValidationRule, NumberValidationRule } from '../../../data/validation/rules'

const getDefaultState = () => {
    return {
        creating: false,
        name: "",
        description: "",
        distributorPrice: "",
        megaDistributorPrice: "",
        superDistributorPrice: "",
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.name, (error) => errors.name = error, { min: { value: 2, error: "Too short" }, max: { value: 20, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.description, (error) => errors.description = error, { min: { value: 2, error: "Too short" }, max: { value: 300, error: "Too long" } })
    validation.addValidationRule(NumberValidationRule, instance.state.distributorPrice, (error) => errors.distributorPrice = error, { min: { value: 1, error: "Too low" }, max: { value: 1000000, error: "Too high" } })
    validation.addValidationRule(NumberValidationRule, instance.state.megaDistributorPrice, (error) => errors.megaDistributorPrice = error, { min: { value: 1, error: "Too low" }, max: { value: 1000000, error: "Too high" } })
    validation.addValidationRule(NumberValidationRule, instance.state.superDistributorPrice, (error) => errors.superDistributorPrice = error, { min: { value: 1, error: "Too low" }, max: { value: 1000000, error: "Too high" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const create = instance => {
    axios({
        method: 'post',
        url: API_ENDPOINT + "/products/create",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            name: instance.state.name,
            description: instance.state.description,
            distributorPrice: instance.state.distributorPrice,
            megaDistributorPrice: instance.state.megaDistributorPrice,
            superDistributorPrice: instance.state.superDistributorPrice,
        }
    }).then(response => {
        if (response.status === 200) {
            instance.setState({
                ...getDefaultState(),
                flag: getFlag(response.data)
            })
        }
    }).catch(error => alert(error))
}

const getFlag = response => {
    switch (response.status) {
        case 200:
            return {
                type: "success",
                text: "Created"
            }
        default:
            return {
                type: "error",
                text: response.message
            }
    }
}

const Service = instance => {
    return {
        isValid: () => isValid(instance),
        create: () => create(instance),
        getDefaultState: () => getDefaultState()
    }
}

export default Service