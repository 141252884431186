import React from 'react'
import CreateRoleWorker from './create'
import { Link, withRouter } from 'react-router-dom'
import RoleForm from '../components/form/form.component'
import FormHelper from '../../../../utils/FormHelper'
import Checkbox from '../../../../components/inputs/checkbox/checkbox.component'
import './create.styles.scss'
import { connect } from 'react-redux'

class CreateRole extends React.Component {

    constructor(props) {
        super(props)
        this.createRoleWorker = CreateRoleWorker(this)
        this.state = this.createRoleWorker.getDefaultState()
    }

    componentDidMount = () => this.createRoleWorker.loadPermissions()

    onPermissionsChanged = permissions => {
        this.setState({
            ...this.state,
            permissions: permissions
        })
    }

    onChangePermission = (name, checked) => this.createRoleWorker.onChangePermission(name)

    onSubmit = (e) => {
        e.preventDefault()
        this.createRoleWorker.isValid() && this.createRoleWorker.createRole()
    }

    onCancel = () => this.props.history.push('/security/roles')

    render() {
        return (
            <div className="create">
                <div className="card">
                    <Link className="btn" to="/security/roles">Roles</Link>
                    <div className="header">
                        <h4>Create Role</h4>
                    </div>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <RoleForm {...this.state} action="Add Role" onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.onCancel}>
                        <h4>Permissions</h4>
                        <div className="row-permissions">
                            {
                                this.state.permissions.map((permission, key) => <Checkbox key={key} name={permission.id} text={permission.name} checked={this.state.grantedPermissionIds.indexOf(permission.id) > -1} onChange={this.onChangePermission} />)
                            }
                        </div>
                    </RoleForm>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(CreateRole))