import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import Validation from '../../../../data/validation/validation'
import { StringValidationRule } from '../../../../data/validation/rules'

const getDefaultState = () => {
    return {
        creating: false,
        name: "",
        errors: {},
        permissions: [],
        grantedPermissionIds: [],
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadPermissions = instance => {
    axios({
        method: 'get',
        url: API_ENDPOINT + "/permissions",
        headers: {
            "Authorization": instance.props.auth.authorization
        }
    }).then(response => {
        if (response.status === 200 && response.data.status === 200) {
            instance.onPermissionsChanged(response.data.data.permissions)
        }
    }).catch(error => alert(error))
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.name, (error) => errors.name = error, { min: { value: 2, error: "Too short" }, max: { value: 20, error: "Too long" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const createRole = (instance) => {
    instance.setState({
        ...instance.state,
        creating: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/roles/create",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            name: instance.state.name,
            grantedPermissionIds: instance.state.grantedPermissionIds
        }
    }).then(response => {
        if (response.status === 200) {
            handleCreateRoleResponse(instance, response.data)
        }
    }).catch(error => alert(error))
}

const handleCreateRoleResponse = (instance, response) => {
    switch (response.status) {
        case 200:
            instance.setState({
                ...getDefaultState(),
                flag: {
                    type: "success",
                    text: "Role Created"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                creating: false,
                flag: {
                    type: "error",
                text: response.message
                }
            })
    }
}

const onChangePermission = (instance, name) => {
    let grantedPermissionIds = instance.state.grantedPermissionIds
    if (instance.state.grantedPermissionIds.indexOf(name) > -1) {
        grantedPermissionIds = instance.state.grantedPermissionIds.filter(permissionId => permissionId !== name)
    }
    else {
        grantedPermissionIds.push(name)
    }
    instance.setState({
        ...instance.state,
        grantedPermissionIds: grantedPermissionIds
    })
}

const CreateRoleWorker = instance => {
    return {
        getDefaultState: getDefaultState,
        loadPermissions: () => loadPermissions(instance),
        isValid: () => isValid(instance),
        createRole: () => createRole(instance),
        onChangePermission: name => onChangePermission(instance, name)
    }
}

export default CreateRoleWorker