import iconDashboard from '../../../assets/icons/dashboard.svg'
import iconPayer from '../../../assets/icons/payer.svg'
import iconSettings from '../../../assets/icons/config.svg'
import iconSecurity from '../../../assets/icons/security.svg'

const DefaultStore = {
    showMenu: false,
    activeItem: 0,
    items: [
        {
            text: "Dashboard",
            icon: iconDashboard,
            subItems: []
        },
        {
            text: "Orders",
            icon: iconPayer,
            subItems: [
                {
                    url: "/orders",
                    text: "Orders"
                }
            ]
        },
        {
            text: "Distributors",
            icon: iconDashboard,
            subItems: [
                {
                    url: "/distributors/approved",
                    text: "Approved"
                },
                {
                    url: "/distributors/pending",
                    text: "Pending"
                }
            ]
        },
        {
            text: "Products",
            icon: iconPayer,
            subItems: [
                {
                    url: "/products",
                    text: "Products"
                }
            ]
        },
        {
            text: "Account",
            icon: iconSettings,
            subItems: [
                {
                    url: "/account/profile",
                    text: "Profile"
                },
                {
                    url: "/account/change-password",
                    text: "Change Password"
                }
            ]
        },
        {
            text: "Security",
            icon: iconSecurity,
            subItems: [
                {
                    url: "/security/users",
                    text: "Users"
                },
                {
                    url: "/security/roles",
                    text: "Roles"
                }
            ]
        }
    ]
}

export default DefaultStore