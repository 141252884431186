const DistributorCategories = [
    {
        label: "Distributor",
        value: 1
    },
    {
        label: "Mega Distributor",
        value: 2
    },
    {
        label: "Super Distributor",
        value: 3
    }
]

export default DistributorCategories