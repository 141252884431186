import axios from 'axios'
import { API_ENDPOINT } from '../../../config'
import Validation from '../../../data/validation/validation'
import { StringValidationRule, NumberValidationRule } from '../../../data/validation/rules'

const getDefaultState = () => {
    return {
        saving: false,
        loading: false,
        name: "",
        description: "",
        distributorPrice: "",
        megaDistributorPrice: "",
        superDistributorPrice: "",
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadProduct = instance => {
    axios({
        method: 'get',
        url: API_ENDPOINT + "/products/" + instance.props.match.params.id,
        headers: {
            "Authorization": instance.props.auth.authorization
        }
    }).then(response => handleLoadProductResponse(instance, response))
    .catch(error => alert(error))
}

const handleLoadProductResponse = (instance, response) => {
    switch(response.data.status){
        case 200:
            instance.setState({
                ...instance.state,
                loading: false,
                name: response.data.data.product.name,
                description: response.data.data.product.description,
                distributorPrice: getPriceByCategory(response.data.data.product.pricings, 1),
                megaDistributorPrice: getPriceByCategory(response.data.data.product.pricings, 2),
                superDistributorPrice: getPriceByCategory(response.data.data.product.pricings, 3)
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            alert(response.data.message)
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.name, (error) => errors.name = error, { min: { value: 2, error: "Too short" }, max: { value: 20, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.description, (error) => errors.description = error, { min: { value: 2, error: "Too short" }, max: { value: 300, error: "Too long" } })
    validation.addValidationRule(NumberValidationRule, instance.state.distributorPrice, (error) => errors.distributorPrice = error, { min: { value: 1, error: "Too low" }, max: { value: 1000000, error: "Too high" } })
    validation.addValidationRule(NumberValidationRule, instance.state.megaDistributorPrice, (error) => errors.megaDistributorPrice = error, { min: { value: 1, error: "Too low" }, max: { value: 1000000, error: "Too high" } })
    validation.addValidationRule(NumberValidationRule, instance.state.superDistributorPrice, (error) => errors.superDistributorPrice = error, { min: { value: 1, error: "Too low" }, max: { value: 1000000, error: "Too high" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const save = instance => {
    axios({
        method: 'post',
        url: API_ENDPOINT + "/products/update",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            id: instance.props.match.params.id,
            name: instance.state.name,
            description: instance.state.description,
            distributorPrice: instance.state.distributorPrice,
            megaDistributorPrice: instance.state.megaDistributorPrice,
            superDistributorPrice: instance.state.superDistributorPrice,
        }
    }).then(response => handleSaveResponse(instance, response))
    .catch(error => alert(error))
}

const handleSaveResponse = (instance, response) => {
    switch(response.data.status){
        case 200:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "success",
                    text: "Updated"
                }
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const getPriceByCategory = (pricings, category) => {
    for(let i = 0; i < pricings.length; i++){
        if(pricings[i].category === category){
            return pricings[i].amount
        }
    }
    return 0
}

const Service = instance => {
    return {
        isValid: () => isValid(instance),
        save: () => save(instance),
        getDefaultState: () => getDefaultState(),
        loadProduct: () => loadProduct(instance)
    }
}

export default Service