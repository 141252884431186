import React from 'react'
import { withRouter } from 'react-router-dom'
import Dialog from '../../components/dialog/dialog.component'
import Loading from '../../components/loading/loading.component'
import { connect } from 'react-redux'
import Filters from './filters.component'
import Pagination from '../../components/pagination/pagination.component'
import './distributors.styles.scss'
import Service from './service'
import {FindOptionByValue} from '../../utils/UIHelper'
import DistributorCategories from '../../assets/data/distributor_categories'

class Distributors extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadDistributors()
    }

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page
        }, () => this.service.loadDistributors())
    }

    onFilterChange = e => {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                value: "",
                [e.target.name]: e.target.value
            }
        })
    }

    onFilterSubmit = e => {
        e.preventDefault()
        this.service.loadDistributors()
    }

    getStatusLabel = status => {
        switch (status) {
            case 1:
                return <span className="pending">Pending</span>
            case 2:
                return <span className="pending">Waiting Approval</span>
            case 3:
                return <span className="active">Approved</span>
            case 4:
                return <span className="inactive">Rejected</span>
            default:
                return <span className="pending">Unknown</span>
        }
    }

    render() {
        return (
            <>
                {this.state.loading && <Dialog><Loading /></Dialog>}
                <div className="distributors">
                    <div className="header">
                        <h4>Distributors</h4>
                        <div className="buttons">
                            <Filters filter={this.state.filter} onSubmit={this.onFilterSubmit} onChange={this.onFilterChange} />
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Number</th>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Category</th>
                                <th>Orders</th>
                                <th>Updated</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.distributors.map((distributor, key) => <tr key={key} onClick={() => this.props.history.push('/distributors/' + distributor.id)}>
                                    <td>{distributor.number}</td>
                                    <td>{distributor.name}</td>
                                    <td>{this.getStatusLabel(distributor.status)}</td>
                                    <td>{FindOptionByValue(DistributorCategories, Number(distributor.category)).label}</td>
                                    <td>0</td>
                                    <td>{new Date(distributor.updatedAt).toString('dd MMM, yyyy')}</td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Distributors))