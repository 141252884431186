import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import Validation from '../../../../data/validation/validation'
import { EmailValidationRule, StringValidationRule, PhoneValidationRule } from '../../../../data/validation/rules'

const getDefaultState = (instance) => {
    return {
        creating: false,
        firstname: "",
        lastname: "",
        phone: "",
        username: "",
        password: "",
        roles: [],
        grantedRoleIds: [],
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadRoles = instance => {
    axios({
        method: 'get',
        url: API_ENDPOINT + "/roles",
        headers: {
            "Authorization": instance.props.auth.authorization
        }
    }).then(response => {
        if (response.status === 200 && response.data.status === 200) {
            onRolesChanged(instance, response.data.data.roles)
        }
    }).catch(error => alert(error))
}

const onRolesChanged = (instance, roles) => {
    instance.setState({
        ...instance.state,
        roles: roles
    })
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.firstname, (error) => errors.firstname = error, { min: { value: 2, error: "Too short" }, max: { value: 25, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.lastname, (error) => errors.lastname = error, { min: { value: 2, error: "Too short" }, max: { value: 25, error: "Too long" } })
    validation.addValidationRule(PhoneValidationRule, instance.state.phone, (error) => errors.phone = error, { allowNull: false })
    validation.addValidationRule(EmailValidationRule, instance.state.username, (error) => errors.username = error, { allowNull: false })
    validation.addValidationRule(StringValidationRule, instance.state.password, (error) => errors.password = error, { min: { value: 6, error: "Too short" }, max: { value: 40, error: "Too long" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const createUser = instance => {
    axios({
        method: 'post',
        url: API_ENDPOINT + "/users/create",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            username: instance.state.username,
            password: instance.state.password,
            firstname: instance.state.firstname,
            lastname: instance.state.lastname,
            phone: instance.state.phone,
            status: 1,
            grantedRoleIds: instance.state.grantedRoleIds
        }
    }).then(response => {
        if (response.status === 200) {
            instance.setState({
                ...getDefaultState(),
                flag: getFlag(response.data)
            })
        }
    }).catch(error => alert(error))
}

const getFlag = response => {
    switch (response.status) {
        case 200:
            return {
                type: "success",
                text: "User Created"
            }
        default:
            return {
                type: "error",
                text: response.message
            }
    }
}

const onChangeRole = (instance, name) => {
    let grantedRoleIds = instance.state.grantedRoleIds
    if (instance.state.grantedRoleIds.indexOf(name) > -1) {
        grantedRoleIds = instance.state.grantedRoleIds.filter(RoleId => RoleId !== name)
    }
    else {
        grantedRoleIds.push(name)
    }
    instance.setState({
        ...instance.state,
        grantedRoleIds: grantedRoleIds
    })
}

const CreateWorker = instance => {
    return {
        loadRoles: () => loadRoles(instance),
        isValid: () => isValid(instance),
        createUser: () => createUser(instance),
        getDefaultState: () => getDefaultState(),
        onChangeRole: name => onChangeRole(instance, name)
    }
}

export default CreateWorker