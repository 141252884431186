import React from 'react'
import { Switch, Route } from "react-router-dom"
import Distributors from './distributors.page'
import Details from './details/details.page'

const DistributorController = () => {
    return (
        <div className="distributors">
            <Switch>
                <Route path="/distributors/approved" component={Distributors} />
                <Route path="/distributors/pending" component={Distributors} />
                <Route path="/distributors/:id" component={Details} />
            </Switch>
        </div>
    )
}

export default DistributorController