import { API_ENDPOINT } from '../../../../config'
import axios from 'axios'

const getDefaultState = () => {
    return {
        saving: false
    }
}

const saveStatus = (instance, status) => {
    instance.setState({
        ...instance.state,
        saving: true
    })

    axios({
        method: 'post',
        url: API_ENDPOINT + "/orders/update",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            id: instance.props.id,
            status: status
        }
    }).then(response => handleSaveStatusResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveStatusResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                saving: false
            })
            instance.props.onStatusUpdated(response.data.data.order.status)
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(response.data.message)
    }
}

const Worker = instance => {
    return {
        getDefaultState: getDefaultState,
        saveStatus: status => saveStatus(instance, status)
    }
}

export default Worker